/*
    UTILITAIRES EXTENSION
    v0.1.0
    Configuration de l'extension des utilitaires génériques CSS ITADS
    https://github.com/ita-design-system/utilities.scss
    Ce fichier doit servir à étendre ou surcharger les fonctionnalités
    du composant c-txt selon les besoins du projet
*/
$briks-utilities: (
    // background: (
    //     enabled: true,
    //     prefix: 'bg-',
    //     names-and-values: (
    //         none: none
    //     ),
    //     pseudo-classes: (hover, active),
    //     responsive: true
    // ),
);